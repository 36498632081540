import React, { useRef, useCallback } from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Scrollspy from "react-scrollspy";
import { Link as ScrollLink } from "react-scroll";
import AutoAffix from "../../../components/affix/AutoAffix";
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";
import { Link } from "../../../components/link";

import FormContatti from "../../../components/form-contatti";

import Square from "../../../svg/square-det.svg";

const hero = [
  {
    title: "Liferay Commerce",
    description: "Come scegliere la piattaforma e-commerce perfetta per il tuo business",
  },
];
const breadcrumb = [
  { title: "Home", link: "/" },
  { title: "Competence center", link: "/competence-center/" },
  { title: "Liferay", link: "/competence-center/liferay/" },
  { title: "Liferay Commerce" },
];

const LiferayEcommerce = ({ data, location }) => {
  const { caratteristiche, digitali, performance, piattaforme } = data;

  const scrollRef = useRef();
  const getScrollContainer = useCallback(() => scrollRef.current, []);

  return (
    <Layout location={location} hero={hero} breadcrumb={breadcrumb}>
      <Seo
        title={`Come scegliere la piattaforma e-commerce perfetta per il tuo business`}
        description={`I nuovi orizzonti, le caratteristiche fondamentali che caratterizzano la piattaforma e-commerce ideale per il tuo business e le migliori soluzioni del 2021.`}
      />

      <div className="section section--blue-light-2">
        <Square className="square d-none d-md-block" />
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-10 offset-md-1 col-xl-8 offset-xl-2">
              <p>
                In questo articolo, il team di Ariadne ha raccolto le informazioni più importanti
                per spiegare i nuovi orizzonti delle piattaforme e-commerce e rispondere alla
                domanda “quali sono le soluzioni migliori per l’e-commerce nel 2021”? Partendo dalle
                caratteristiche fondamentali che caratterizzano la piattaforma ideale, e concludendo
                con le possibilità che offre{" "}
                <Link to="/competence-center/liferay/" title="Liferay - Ariadne">
                  Liferay
                </Link>
                , benvenuti in questo breve viaggio alla scoperta delle fondamenta di uno degli
                strumenti business più potenti del web.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="section">
        <div className="container">
          <div className="row">
            <div className="col-md-3 col-xl-4 d-none d-md-block">
              <AutoAffix
                affixClassName="sidebar sidebar--affix"
                topClassName="sidebar sidebar--affix-top"
                bottomClassName="sidebar sidebar--affix-bottom"
                viewportOffsetTop={60}
                container={getScrollContainer}
              >
                <div>
                  <nav className="anchor-nav">
                    <Scrollspy
                      items={[
                        "che-cosa-e-una-piattaforma-e-commerce",
                        "quali-sono-le-caratteristiche-fondamentali-di-una-piattaforma-e-commerce",
                        "i-canali-digitali-per-piattaforme-e-commerce-meglio-mobile-app-o-website",
                        "disponibilita-performance-e-scalabilita-ovvero-anything-as-a-service-xaas",
                        "le-principali-piattaforme-e-commerce-a-confronto",
                      ]}
                      offset={-60}
                      className="anchor-nav__list"
                      currentClassName="anchor-nav__item--active"
                    >
                      <li className="anchor-nav__item">
                        <ScrollLink
                          href={`#che-cosa-e-una-piattaforma-e-commerce`}
                          to={`che-cosa-e-una-piattaforma-e-commerce`}
                          className="anchor-nav__link"
                          title="Che cosa è una piattaforma e-commerce?"
                        >
                          Che cosa è una piattaforma e-commerce?
                        </ScrollLink>
                      </li>
                      <li className="anchor-nav__item">
                        <ScrollLink
                          href={`#quali-sono-le-caratteristiche-fondamentali-di-una-piattaforma-e-commerce`}
                          to={`quali-sono-le-caratteristiche-fondamentali-di-una-piattaforma-e-commerce`}
                          className="anchor-nav__link"
                          title="Quali sono le caratteristiche fondamentali di una piattaforma e-commerce?"
                        >
                          Quali sono le caratteristiche fondamentali di una piattaforma e-commerce?
                        </ScrollLink>
                      </li>
                      <li className="anchor-nav__item">
                        <ScrollLink
                          href={`#i-canali-digitali-per-piattaforme-e-commerce-meglio-mobile-app-o-website`}
                          to={`i-canali-digitali-per-piattaforme-e-commerce-meglio-mobile-app-o-website`}
                          className="anchor-nav__link"
                          title="I canali digitali per piattaforme e-commerce: meglio mobile App o website?"
                        >
                          I canali digitali per piattaforme e-commerce: meglio mobile App o website?
                        </ScrollLink>
                      </li>
                      <li className="anchor-nav__item">
                        <ScrollLink
                          href={`#disponibilita-performance-e-scalabilita-ovvero-anything-as-a-service-xaas`}
                          to={`disponibilita-performance-e-scalabilita-ovvero-anything-as-a-service-xaas`}
                          className="anchor-nav__link"
                          title="Disponibilità, performance e scalabilità. Ovvero: Anything as a service (xAAS)"
                        >
                          Disponibilità, performance e scalabilità. Ovvero: Anything as a service
                          (xAAS)
                        </ScrollLink>
                      </li>
                      <li className="anchor-nav__item">
                        <ScrollLink
                          href={`#le-principali-piattaforme-e-commerce-a-confronto`}
                          to={`le-principali-piattaforme-e-commerce-a-confronto`}
                          className="anchor-nav__link"
                          title="Le principali piattaforme e-commerce a confronto"
                        >
                          Le principali piattaforme e-commerce a confronto
                        </ScrollLink>
                      </li>
                    </Scrollspy>
                  </nav>
                </div>
              </AutoAffix>
            </div>
            <div className="col-md-8 offset-md-1 col-xl-7 offset-xl-1 mb-5">
              <div className="anchor-text" ref={scrollRef}>
                <div id="che-cosa-e-una-piattaforma-e-commerce" className="anchor">
                  <h2 className="anchor-text__title">Che cosa è una piattaforma e-commerce?</h2>
                  <p>
                    Le piattaforme e-commerce sono alla base del commercio online.{" "}
                    <strong>
                      Si tratta di software con cui è possibile creare, lanciare e gestire negozi
                      online.
                    </strong>
                  </p>
                  <p>
                    In altre parole, la piattaforma e-commerce contiene{" "}
                    <strong>
                      le funzionalità necessarie per costruire una vetrina online dove vendere
                      prodotti e servizi
                    </strong>
                    .
                  </p>
                  <p>
                    Anche il commercio online, così come quello tradizionale, utilizza software
                    differenti e specifici per gestire ogni fase del processo di vendita. Ad esempio
                    la gestione degli ordini, del magazzino o delle richieste per il customer
                    service. Pensare di comporre una piattaforma come aggregazione di tutte queste
                    componenti è possibile, e spesso è necessario partire da questi elementi, ma
                    sicuramente progettare una piattaforma e-commerce in questo modo presenta dei
                    limiti: un processo complesso, con implicazioni difficili da governare
                    nell'erogazione del sistema e in ultima istanza sulla sua disponibilità.
                  </p>
                  <p>
                    È proprio per superare questo limite che deriva l'idea di creare{" "}
                    <strong>piattaforme per l'e-commerce *ready-to-run*</strong>, cioè soluzioni che
                    comprendano le funzioni essenziali in modo integrato.{" "}
                    <strong>Il vantaggio principale</strong> di questo approccio è che la sintesi
                    creata da queste piattaforme non si limita alla mera integrazione tecnica di
                    componenti - attraverso l'adattamento delle loro interfaccia software - ma punta{" "}
                    <strong>
                      a creare valore attraverso una coerenza di esperienza in tutto il processo
                      dell'acquisto online.
                    </strong>
                  </p>
                  <p>
                    In altre parole, una piattaforma e-commerce progettata in modo integrato
                    garantisce un’esperienza utente migliore e allo stesso tempo ottimizza il flusso
                    tra tutti gli aspetti funzionali.
                  </p>
                </div>
                <div
                  id="quali-sono-le-caratteristiche-fondamentali-di-una-piattaforma-e-commerce"
                  className="anchor"
                >
                  <GatsbyImage
                    image={getImage(caratteristiche)}
                    alt="Image caratteristiche"
                    className="w-100"
                  />
                  <h2 className="anchor-text__title">
                    Quali sono le caratteristiche fondamentali di una piattaforma e-commerce?
                  </h2>
                  <p>
                    Abbiamo individuato gli elementi di una piattaforma e-commerce funzionale ed
                    efficace:
                  </p>
                  <ul>
                    <li>
                      <strong>UNIFIED</strong> Occorre integrare a livello nativo di tutte le
                      funzioni essenziali del processo di vendita online, dalle funzioni di
                      promozione e marketing al catalogo agli ordini e pagamenti.
                    </li>
                    <li>
                      <strong>CUSTOMER CENTRIC</strong> Per offrire al potenziale cliente
                      un’esperienza di acquisto coerente attraverso tutti i canali e{" "}
                      <em>touch-point</em>, con comunicazioni mirate e personalizzate e un servizio
                      eccellente.
                    </li>
                    <li>
                      <strong>FLESSIBILE</strong> La flessibilità della gestione degli ordini
                      consentire di organizzare il miglior processo in cui i clienti possano
                      trovare, acquistare, ricevere ed eventualmente restituire i prodotti.
                    </li>
                    <li>
                      <strong>USER FRIENDLY</strong> Attraverso interfacce utenti accattivanti e
                      semplici, è possibile offrire al cliente una user experience appagante su ogni
                      canale digitale.
                    </li>
                    <li>
                      <strong>APERTA</strong> alle estensioni successive Per offrire la possibilità
                      di configurare diversi modelli di business, di personalizzare differenti
                      brand, canali, valute, lingue,...
                    </li>
                    <li>
                      <strong>SICURA</strong> La piattaforma deve essere conforme agli standard di
                      sicurezza del settore.
                    </li>
                    <li>
                      <strong>PERFORMANTE</strong> Per offrire performance di interazione sui canali
                      digitali in linea con i migliori sistemi di e-commerce disponibili.
                    </li>
                    <li>
                      <strong>SCALABILE</strong> Per gestire in modo semplice e rapido picchi, anche
                      sostenuti, di richieste.
                    </li>
                    <li>
                      <strong>COMPRENSIVA DI CMS</strong> Una efficiente gestione dei contenuti
                      garantisce modalità smart di creare ed aggiornare i contenuti, le pagine, i
                      form, ecc., senza trascurare le necessità di poter essere trovati facilmente
                      sulla rete - e quindi integrando la gestione delle informazioni necessarie per
                      implementazioni SEO/SEM corrette.
                    </li>
                    <li>
                      <strong>COMPRENSIVA DI CMS</strong> Per offrire la possibilità di un supporto
                      di alto livello, ovvero che possa rapidamente scalare affinché il servizio
                      possa operare idealmente senza soluzione di continuità.
                    </li>
                  </ul>
                </div>
                <div
                  id="i-canali-digitali-per-piattaforme-e-commerce-meglio-mobile-app-o-website"
                  className="anchor"
                >
                  <GatsbyImage
                    image={getImage(digitali)}
                    alt="Image canali digitali"
                    className="anchor-text__img"
                  />
                  <h2 className="anchor-text__title">
                    I canali digitali per piattaforme e-commerce: meglio mobile App o website?
                  </h2>
                  <p>
                    Le{" "}
                    <a
                      href="https://www.outerboxdesign.com/web-design-articles/mobile-ecommerce-statistics"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      statistiche
                    </a>{" "}
                    rilevano che negli ultimi 6 mesi l'80% delle persone che possiedono uno
                    smartphone l’hanno utilizzato per fare acquisti online, e che una percentuale
                    sempre 80% di persone utilizzano lo smartphone durante la loro visita in un
                    negozio fisico per acquisire ulteriori informazioni per l'acquisto.
                  </p>
                  <p>
                    Con questi dati risulta{" "}
                    <strong>
                      evidente la necessità di considerare il canale mobile per coinvolgere la
                      clientela
                    </strong>{" "}
                    che utilizza principalmente lo smartphone. In effetti, in parallelo con
                    l’evoluzione della tecnologia, l'esperienza di acquisto su questo canale si fa
                    per l'utente sempre più semplice e accattivante.{" "}
                  </p>
                  <p>
                    Tuttavia, se lo smartphone è sicuramente il canale privilegiato, non è
                    altrettanto netta la prevalenza in termini di utilizzo di una mobile App
                    rispetto a un website e-commerce. Anzi, alcuni dati ci dicono che, spesso,{" "}
                    <strong>
                      l'utilizzo della versione web (pur via smartphone) di un e-commerce viene
                      preferito dagli utenti
                    </strong>{" "}
                    rispetto ad una mobile App dedicata.{" "}
                  </p>
                  <p>
                    Ora, senza volere indagare il fenomeno, quello che ci interessa evidenziare è
                    che pur presentando, eventualmente, le stesse funzionalità, entrambi gli
                    approcci mobile App e website appaiono vincenti e, in particolare, ciò avviene
                    in momenti diversi del processo di vendita: i website vengono preferiti in fase
                    di individuazione del prodotto, mentre le mobile App sono strategiche per
                    incentivare i clienti di ritorno.
                  </p>
                  <p>
                    In sintesi, <strong>è importante investire in entrambi i canali</strong>.
                  </p>
                </div>
                <div
                  id="disponibilita-performance-e-scalabilita-ovvero-anything-as-a-service-xaas"
                  className="anchor"
                >
                  <GatsbyImage
                    image={getImage(performance)}
                    alt="Image performance"
                    className="anchor-text__img"
                  />
                  <h2 className="anchor-text__title">
                    Disponibilità, performance e scalabilità. Ovvero: Anything as a service (xAAS)
                  </h2>
                  <p>
                    Poco fa abbiamo indicato - tra le caratteristiche vincenti di una piattaforma di
                    e-commerce: performance, scalabilità e sicurezza. Per garantire questi benefici
                    un aiuto importante ci arriva dal Cloud, a cui possiamo delegare la gestione di
                    questi aspetti. Sul Cloud infatti sono disponibili diversi{" "}
                    <a
                      href="https://mycloudblog7.wordpress.com/2013/06/19/who-manages-cloud-iaas-paas-and-saas-services/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      modelli di servizio
                    </a>{" "}
                    per piattaforme di e-commerce: IAAS, PAAS, SAAS. Questo rende le piattaforme
                    "on-premise" un’opzione secondaria e di certo non preferenziale, seppur
                    praticabile e in alcuni casi necessaria. Ecco alcune delle possibilità che offre
                    il Cloud:{" "}
                  </p>
                  <h3>IAAS - Infrastructure As A Service</h3>
                  <p>
                    A questo livello il Cloud viene utilizzato come alternativa alla infrastruttura
                    IT tradizionale. A livello IAAS è possibile acquisire a richiesta le risorse IT
                    che servono, quando servono, senza dover gestire gli asset IT. Applicare questo
                    livello ad una piattaforma e-commerce significa acquisire la piattaforma e poi
                    dispiegarla sulle risorse Cloud opportunamente configurate. Sono quindi
                    necessarie competenze sia a livello dell'applicazione di e-commerce per gestirla
                    e per sviluppare le personalizzazioni che a livello IT per installarla e
                    mantenerla operativa.
                  </p>
                  <h3>PAAS - Platform As A Service</h3>
                  <p>
                    A questo livello il Cloud viene utilizzato dagli sviluppatori per comporre e
                    configurare il sistema finale. Essendo in Cloud anche in questo caso abbiamo
                    performance, sicurezza e scalabilità che però sono demandati al gestore Cloud
                    della piattaforma. Il sistema risultante è comunque "unico" nel senso che viene
                    realizzato a livello di sviluppo su specifiche necessità.
                  </p>
                  <h3>SAAS - Software As A Service</h3>
                  <p>
                    A questo livello il Cloud offre una gestione completa anche a livello
                    applicativo. Non ci sono parti da sviluppare, ma solo opzioni da configurare. È
                    possibile quindi concentrarsi solo sulle necessità di business piuttosto di
                    quelle operative del servizio online.
                  </p>
                </div>
                <div id="le-principali-piattaforme-e-commerce-a-confronto" className="anchor">
                  <GatsbyImage
                    image={getImage(piattaforme)}
                    alt="Image piattaforme"
                    className="anchor-text__img"
                  />
                  <h2 className="anchor-text__title">
                    Le principali piattaforme e-commerce a confronto
                  </h2>
                  <p>
                    Poco fa abbiamo indicato - tra le caratteristiche vincenti di una piattaforma di
                    e-commerce: performance, scalabilità e sicurezza. Per garantire questi benefici
                    un aiuto importante ci arriva dal Cloud, a cui possiamo delegare la gestione di
                    questi aspetti. Sul Cloud infatti sono disponibili diversi{" "}
                    <a
                      href="https://mycloudblog7.wordpress.com/2013/06/19/who-manages-cloud-iaas-paas-and-saas-services/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      modelli di servizio
                    </a>{" "}
                    per piattaforme di e-commerce: IAAS, PAAS, SAAS. Questo rende le piattaforme
                    "on-premise" un’opzione secondaria e di certo non preferenziale, seppur
                    praticabile e in alcuni casi necessaria. Ecco alcune delle possibilità che offre
                    il Cloud:{" "}
                  </p>
                  <h3>IAAS - Infrastructure As A Service</h3>
                  <p>
                    A questo livello il Cloud viene utilizzato come alternativa alla infrastruttura
                    IT tradizionale. A livello IAAS è possibile acquisire a richiesta le risorse IT
                    che servono, quando servono, senza dover gestire gli asset IT. Applicare questo
                    livello ad una piattaforma e-commerce significa acquisire la piattaforma e poi
                    dispiegarla sulle risorse Cloud opportunamente configurate. Sono quindi
                    necessarie competenze sia a livello dell'applicazione di e-commerce per gestirla
                    e per sviluppare le personalizzazioni che a livello IT per installarla e
                    mantenerla operativa.
                  </p>
                  <h3>PAAS - Platform As A Service</h3>
                  <p>
                    A questo livello il Cloud viene utilizzato dagli sviluppatori per comporre e
                    configurare il sistema finale. Essendo in Cloud anche in questo caso abbiamo
                    performance, sicurezza e scalabilità che però sono demandati al gestore Cloud
                    della piattaforma. Il sistema risultante è comunque "unico" nel senso che viene
                    realizzato a livello di sviluppo su specifiche necessità.
                  </p>
                  <h3>SAAS - Software As A Service</h3>
                  <p>
                    A questo livello il Cloud offre una gestione completa anche a livello
                    applicativo. Non ci sono parti da sviluppare, ma solo opzioni da configurare. È
                    possibile quindi concentrarsi solo sulle necessità di business piuttosto di
                    quelle operative del servizio online.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FormContatti location={location} />
    </Layout>
  );
};

export default LiferayEcommerce;

export const query = graphql`
  query {
    caratteristiche: file(relativePath: { eq: "pages/liferay-commerce-caratteristiche.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 700, layout: CONSTRAINED)
      }
    }
    digitali: file(relativePath: { eq: "pages/liferay-commerce-digitali.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 700, layout: CONSTRAINED)
      }
    }
    performance: file(relativePath: { eq: "pages/liferay-commerce-performance.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 700, layout: CONSTRAINED)
      }
    }
    piattaforme: file(relativePath: { eq: "pages/liferay-commerce-piattaforme.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 700, layout: CONSTRAINED)
      }
    }
  }
`;
